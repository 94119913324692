import Route from '@ember/routing/route'
import { inject as service } from '@ember/service'
import type RouterService from '@ember/routing/router-service'
import type { FeatureService } from '@blakeelearning/features'
import type UserService from 're-client/services/user'
import { assert, debug } from '@ember/debug'
import type StudentProgressService from 're-client/services/student-progress'
import type CertificateService from 're-client/services/certificate'

export default class LessonsCertificateRoute extends Route {
  @service declare certificate: CertificateService

  @service declare user: UserService

  @service declare studentProgress: StudentProgressService

  @service declare features: FeatureService

  @service declare router: RouterService

  override buildRouteInfoMetadata() {
    return {
      willHideLoadingScreenManually: true,
    }
  }

  override async model() {
    const { student } = await this.certificate.getPendingCertificate()

    if (!student?.pendingCertificate) {
      debug(`[CertificateRoute] model() - no pending certificate`)
      await this.router.replaceWith('lessons.map.next')
      assert('pendingCertificate exists', student?.pendingCertificate)
    }

    const {
      map,
      colour,
      scorePercentage: percentage,
      quizResultCreatedAt: date,
      id: pendingCertificateId,
    } = student.pendingCertificate

    const manifests = [
      ['map-certificate', `maps/${map.toString().padStart(3, '0')}`],
    ]

    const mapResult = {
      date,
      percentage,
      colour,
    }

    return {
      variables: { studentName: student.name, mapResult },
      manifests,
      map,
      pendingCertificateId,
    }
  }
}
