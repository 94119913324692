import ApolloService from 'ember-apollo-client/services/apollo'
import type {
  ApolloLink,
  ApolloClientOptions,
  NormalizedCacheObject,
  QueryOptions,
  OperationVariables,
  MutationOptions,
} from '@apollo/client/core'
import { createHttpLink, from, InMemoryCache } from '@apollo/client/core'
import introspectionResult from 're-client/graphql/introspection-result'
import config from 're-client/config/environment'
import type { TypedTypePolicies } from 're-client/graphql/apollo-helpers'
import { capitalize } from '@ember/string'
import { waitFor } from '@ember/test-waiters'
import { onError } from '@apollo/client/link/error'
import { loadErrorMessages, loadDevMessages } from '@apollo/client/dev'
import { runInDebug } from '@ember/debug'
import { service } from '@ember/service'
import type { Log } from '@blakeelearning/log'

runInDebug(() => {
  loadDevMessages()
  loadErrorMessages()
})

const {
  APP: { apiEndpoint, apollo },
} = config

let apolloURL = '/graphql'

if (apiEndpoint) {
  apolloURL = new URL(apolloURL, apiEndpoint).href
}

const typePolicies: TypedTypePolicies = {
  Student: {
    fields: {
      name: {
        read(_, { readField }) {
          const firstName = readField({ fieldName: 'firstName' })
          const lastName = readField({ fieldName: 'lastName' })
          if (typeof firstName === 'string' && typeof lastName === 'string') {
            return `${capitalize(firstName)} ${capitalize(lastName.charAt(0))}.`
          }

          return undefined
        },
      },
      remoteId: {
        read(_, { readField }) {
          const remoteId = readField({ fieldName: 'id' })
          if (typeof remoteId === 'string') {
            return remoteId
          }

          return undefined
        },
      },
      dnaHash: {
        read(_, { readField }) {
          const dna = readField({ fieldName: 'dna' })

          if (
            dna !== null &&
            typeof dna === 'object' &&
            '__typename' in dna &&
            dna.__typename === 'AvatarDna'
          ) {
            const dnaClone = { ...dna }
            delete dnaClone.__typename
            const dnaHash = JSON.stringify(dnaClone)
            return dnaHash
          }

          return undefined
        },
      },
    },
  },
  AssignmentTaskLesson: {
    fields: {
      targetRouteParams: {
        read(_, { readField }) {
          const lessonId = readField({ fieldName: 'lessonId' })
          const activityId = readField({ fieldName: 'activityId' })
          return ['lessons.lesson.activity', lessonId, activityId]
        },
      },
    },
  },
  AssignmentTaskSpelling: {
    fields: {
      targetRouteParams: {
        read(_, { readField }) {
          const lessonId = readField({ fieldName: 'lessonId' })
          const activityId = readField({ fieldName: 'activityId' })
          return ['spelling.lesson.activity', lessonId, activityId]
        },
      },
    },
  },
  AssignmentTaskClinkerCastle: {
    fields: {
      targetRouteParams: {
        read(_, { readField }) {
          const lessonId = readField({ fieldName: 'lessonId' })
          const activityId = readField({ fieldName: 'activityId' })
          return ['storylands.lesson.activity', lessonId, activityId]
        },
      },
    },
  },
  AssignmentTaskDrivingTests: {
    fields: {
      targetRouteParams: {
        read(_, { readField }) {
          const lessonId = readField({ fieldName: 'lessonId' })
          const category = readField({ fieldName: 'category' })
          return ['driving-tests.quiz', category, lessonId]
        },
      },
    },
  },
}

export default class OverriddenApollo extends ApolloService<NormalizedCacheObject> {
  @service
  declare log: Log

  override clientOptions(): ApolloClientOptions<NormalizedCacheObject> {
    const options = super.clientOptions()

    if (typeof apollo.connectToDevTools === 'boolean') {
      return {
        ...options,
        connectToDevTools: apollo.connectToDevTools,
      }
    }

    return options
  }

  override cache(): InMemoryCache {
    return new InMemoryCache({
      typePolicies,
      /**
       * Declare Possible Types
       *
       * This is required for when we use fragments on union types or interfaces
       * Otherwise Apollo doesn't know what to do, and strips all the fields from the response
       * @see {@link https://github.com/apollographql/apollo-client/issues/7050}
       * @see {@link https://www.apollographql.com/docs/react/data/fragments/#using-fragments-with-unions-and-interfaces}
       */
      possibleTypes: introspectionResult.possibleTypes,
    })
  }

  override link(): ApolloLink {
    const httpLink = createHttpLink({
      uri: apolloURL,
      credentials: 'include',
    })

    const errorLink = onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, ...error }) => {
          this.log.error(message, error)
        })
      }

      if (networkError) {
        this.log.error(networkError)
      }
    })

    return from([errorLink, httpLink])
  }

  @waitFor
  override async mutate<
    T = unknown,
    TVariables extends OperationVariables = OperationVariables,
  >(opts: MutationOptions<T, TVariables>) {
    const result = await this.client.mutate(opts)

    return result.data
  }

  @waitFor
  override async query<T = unknown>(opts: QueryOptions<OperationVariables, T>) {
    const result = await this.client.query(opts)

    return result.data
  }
}

declare module '@ember/service' {
  interface Registry {
    apollo: OverriddenApollo
  }
}
