function createFocusableWalker(parent: Node) {
  return document.createTreeWalker(parent, NodeFilter.SHOW_ELEMENT, (node) => {
    if (node instanceof HTMLElement && node.tabIndex < 0) {
      return NodeFilter.FILTER_SKIP
    }

    if (
      node instanceof HTMLInputElement &&
      node.type !== 'hidden' &&
      !node.disabled
    ) {
      return NodeFilter.FILTER_ACCEPT
    }

    if (
      (node instanceof HTMLButtonElement ||
        node instanceof HTMLSelectElement ||
        node instanceof HTMLTextAreaElement) &&
      !node.disabled
    ) {
      return NodeFilter.FILTER_ACCEPT
    }

    if (node instanceof HTMLAnchorElement && node.hasAttribute('href')) {
      return NodeFilter.FILTER_ACCEPT
    }

    return NodeFilter.FILTER_SKIP
  })
}

export function focusFirstDescendant(parent: Node) {
  const walker = createFocusableWalker(parent)

  const focusable = walker.nextNode()

  if (
    focusable &&
    'focus' in focusable &&
    typeof focusable.focus === 'function'
  ) {
    focusable.focus()
  }
}

export function focusableChildren(parent: Node) {
  const walker = createFocusableWalker(parent)

  const elements = []

  while (walker.nextNode()) {
    if (
      walker.currentNode instanceof HTMLElement ||
      walker.currentNode instanceof SVGElement
    ) {
      elements.push(walker.currentNode)
    }
  }

  return elements
}

export function focusElement(element: Element | null) {
  return () => {
    if (element && 'focus' in element && typeof element.focus === 'function') {
      element.focus()
    }
  }
}
