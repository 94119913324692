import Controller from '@ember/controller'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import config from 're-client/config/environment'
import { graphql } from 're-client/graphql'
import { contentError } from 're-client/utils/errors'
import type { ErrorLike } from 're-client/utils/errors'
import { debugAction } from 're-client/utils/debug'
import { calculateMap } from 're-client/utils/progress-tools'
import type StudentProgressService from 're-client/services/student-progress'
import type ActivityService from 're-client/services/activity'
import type ErrorHandlerService from 're-client/services/error-handler'
import type RouterService from '@ember/routing/router-service'
import type UserService from 're-client/services/user'
import type LoadingUiService from 're-client/services/loading-ui'
import type { ModelFor } from 're-client/utils/route-model'
import { assert } from '@ember/debug'
import { v4 as uuidV4 } from 'uuid'
import type LessonsLessonActivityRoute from 're-client/routes/lessons/lesson/activity'
import type AssignmentsService from 're-client/services/assignments'
import type ActivitySidebarService from 're-client/services/activity-sidebar'
import { useMutation } from 're-client/resources/mutation'

const {
  APP: { product },
  studentProgress: {
    progress: {
      lessons: { lessonsPerMap },
    },
  },
} = config

const answerValues = {
  correct: [
    {
      correctAnswer: '18',
      isCorrect: false,
      studentAnswer: '17',
      number: 1,
    },
    {
      correctAnswer: '1',
      isCorrect: true,
      studentAnswer: '1',
      number: 2,
    },
    {
      correctAnswer: '12',
      isCorrect: true,
      studentAnswer: '12',
      number: 3,
    },
    {
      correctAnswer: '12',
      isCorrect: true,
      studentAnswer: '12',
      number: 4,
    },
    {
      correctAnswer: '12',
      isCorrect: true,
      studentAnswer: '12',
      number: 5,
    },
    {
      correctAnswer: '12',
      isCorrect: true,
      studentAnswer: '12',
      number: 6,
    },
    {
      correctAnswer: '12',
      isCorrect: true,
      studentAnswer: '12',
      number: 7,
    },
    {
      correctAnswer: '12',
      isCorrect: true,
      studentAnswer: '12',
      number: 8,
    },
    {
      correctAnswer: '12',
      isCorrect: true,
      studentAnswer: '12',
      number: 9,
    },
    {
      correctAnswer: '12',
      isCorrect: true,
      studentAnswer: '12',
      number: 10,
    },
  ],
  incorrect: [
    {
      correctAnswer: '18',
      isCorrect: false,
      studentAnswer: '17',
      number: 1,
    },
    {
      correctAnswer: '2',
      isCorrect: false,
      studentAnswer: '1',
      number: 2,
    },
    {
      correctAnswer: '13',
      isCorrect: false,
      studentAnswer: '12',
      number: 3,
    },
    {
      correctAnswer: '13',
      isCorrect: false,
      studentAnswer: '12',
      number: 4,
    },
    {
      correctAnswer: '13',
      isCorrect: false,
      studentAnswer: '12',
      number: 5,
    },
    {
      correctAnswer: '13',
      isCorrect: false,
      studentAnswer: '12',
      number: 6,
    },
    {
      correctAnswer: '13',
      isCorrect: false,
      studentAnswer: '12',
      number: 7,
    },
    {
      correctAnswer: '13',
      isCorrect: false,
      studentAnswer: '12',
      number: 8,
    },
    {
      correctAnswer: '13',
      isCorrect: false,
      studentAnswer: '12',
      number: 9,
    },
    {
      correctAnswer: '13',
      isCorrect: false,
      studentAnswer: '12',
      number: 10,
    },
  ],
}

const saveSkillsQuizResultMutationDocument = graphql(/* GraphQL */ `
  mutation SaveSkillsQuizResult(
    $input: ReadingEndOfLessonQuizResultCreateInput!
  ) {
    readingEndOfLessonQuizResultCreate(input: $input) {
      readingEndOfLessonQuizResult {
        attempt
        autoPassed
        lessonInPrecinct
        passed
        eggsEarned
      }
      student {
        id
        badges {
          id
          lesson
          colour
        }
        ...StudentProgressFragment
        ...StudentQuestDataFragment
        ...QuestGoalEssentialDataFragment
        ...AssignmentTask
      }
    }
  }
`)

export const saveReadingLessonActivityResultMutationDocument = graphql(
  /* GraphQL */ `
    mutation SaveReadingLessonActivityResult(
      $input: ReadingLessonActivityResultCreateInput!
    ) {
      readingLessonActivityResultCreate(input: $input) {
        readingLessonActivityResult {
          reward {
            eggs
          }
        }
        student {
          id
          ...StudentProgressFragment
          ...AssignmentTask
          ...StudentQuestDataFragment
          ...QuestGoalEssentialDataFragment
        }
      }
    }
  `,
)

interface SkillsQuizStudentAnswer {
  correctAnswer: string
  isCorrect: boolean
  number: number
  studentAnswer: string
}

interface SkillsQuizResult {
  answers: SkillsQuizStudentAnswer[]
  uuid: string
}

/**
 * The lesson activity controller
 */
export default class LessonsLessonActivityController extends Controller {
  declare model: ModelFor<LessonsLessonActivityRoute>

  @service declare studentProgress: StudentProgressService

  @service declare assignments: AssignmentsService

  @service declare activity: ActivityService

  @service declare errorHandler: ErrorHandlerService

  @service declare router: RouterService

  @service declare user: UserService

  @service declare loadingUi: LoadingUiService

  @service declare activitySidebar: ActivitySidebarService

  get isAssignmentMode() {
    return this.model.isAssignmentMode
  }

  get lessonId() {
    return this.model.lessonId
  }

  get activityId() {
    return this.model.activityId
  }

  get activityIndex() {
    return this.activityId - 1
  }

  get nextActivity() {
    return this.activity.nextReadingActivityForLesson(
      this.lessonId,
      this.activityId,
    )
  }

  get lessonInPrecinct() {
    const { lessonInPrecinct } = this.model
    assert('model has lessonInPrecinct', lessonInPrecinct)
    return lessonInPrecinct
  }

  get contentSpec() {
    return {
      goNext: {
        type: 'message',
      },
      goToMap: {
        type: 'message',
      },
      incrementScore: {
        type: 'message',
      },
      readyForUserInput: {
        type: 'message',
      },
      saveSkillsQuizResult: {
        type: 'message',
        recoverableErrors: ['SaveFailed'],
      },
      unhandledError: {
        type: 'message',
      },
    }
  }

  get currentPositionTitle() {
    if (this.isAssignmentMode) return this.lessonId

    const currentLesson = this.studentProgress.lessonsCurrentLesson
    if (currentLesson === 'quiz') {
      return 'Quiz'
    }
    return currentLesson
  }

  get student() {
    return this.user.student
  }

  get remoteId() {
    return this.student.remoteId
  }

  get studentContext() {
    let taskId
    let assignmentUuid

    if (
      this.assignments.currentTask?.__typename === 'AssignmentTaskLesson' &&
      this.assignments.canCompleteLessonAssignmentTask(
        this.lessonId,
        this.activityId,
      )
    ) {
      taskId = this.assignments.currentTask.id
      assignmentUuid = this.assignments.currentTask.assignmentUuid
    }

    return {
      product,
      precinct: 'lessons',
      remoteId: this.remoteId,
      taskId,
      assignmentUuid,
    }
  }

  get mapId() {
    return calculateMap(parseInt(this.lessonId, 10), lessonsPerMap)
  }

  saveSkillsQuizResultMutation = useMutation(
    this,
    saveSkillsQuizResultMutationDocument,
  )

  saveReadingLessonActivityResultMutation = useMutation(
    this,
    saveReadingLessonActivityResultMutationDocument,
  )

  @action
  @debugAction()
  async completeActivity() {
    await this.saveProgress()
    this.next()
  }

  /**
   * Transition to the next activity in the lesson if there is one.
   * On the last activity, when not in an assignment, transition to the map for activity.
   * On the last activity, when in an assignment, transition to the students
   * current map based on progress
   */
  @action
  next() {
    if (this.nextActivity) {
      void this.router.transitionTo(
        'lessons.lesson.activity',
        this.nextActivity.activityInLesson,
      )
    } else {
      this.goToMap()
    }
  }

  /**
   * Both Caper and Jester activities share this action
   * Jester action is a message action which receives a single JSON-serializable object argument
   */
  @action
  @debugAction({ eggsToIncrement: { type: 'number' } })
  incrementScore(args: number | Record<'eggsToIncrement', number>) {
    if (typeof args === 'number') {
      // CAPER
      this.user.incrementEggs(args)
    } else {
      // JESTER
      this.activitySidebar.open(args.eggsToIncrement)
    }
  }

  /**
   * Completes activities and assignment tasks for Caper activities
   */
  @action
  async saveProgress(args?: { uuid: string } | undefined) {
    let assignment = null

    if (
      this.assignments.currentTask?.__typename === 'AssignmentTaskLesson' &&
      this.assignments.canCompleteLessonAssignmentTask(
        this.lessonId,
        this.activityId,
      )
    ) {
      assignment = {
        assignmentTaskId: parseInt(this.assignments.currentTask.id, 10),
        assignmentUuid: this.assignments.currentTask.assignmentUuid,
      }
    }

    let result

    try {
      result =
        await this.saveReadingLessonActivityResultMutation.current.mutate({
          variables: {
            input: {
              lessonInPrecinct: parseInt(this.lessonId, 10),
              activityInLesson: this.activityId,
              attemptUuid: args?.uuid ?? uuidV4(),
              assignment,
            },
          },
        })
    } catch (error) {
      this.errorHandler.handleError(
        '[SaveReadingLessonActivityResult] mutation failed',
        error,
      )
    }

    return result?.readingLessonActivityResultCreate.readingLessonActivityResult
  }

  /**
   * Transition to a specific activity
   */
  @action
  changeActivity(newActivityID: number) {
    void this.router.transitionTo('lessons.lesson.activity', newActivityID)
  }

  /**
   * Transition to the map for this lesson
   * If user is on an assignment, transition to their current lesson instead
   */
  @debugAction()
  @action
  goToMap() {
    if (this.isAssignmentMode) {
      void this.router.transitionTo(
        'lessons.map',
        this.studentProgress.lessonsCurrentMap,
      )
    } else {
      const mapIdForLessonActivity = this.mapId
      void this.router.transitionTo('lessons.map', mapIdForLessonActivity)
    }
  }

  /**
   * Transition to next activity.
   * This is a Jester content action
   */
  @debugAction()
  @action
  goNext() {
    this.next()
  }

  /**
   * Hide the full page loader when the content is loaded.
   * This is a Jester content action
   */
  @action
  readyForUserInput() {
    this.loadingUi.hide() // clear the loading screen
  }

  /**
   * If the student passed and the quiz was an assignment then complete the task.
   * This is a Jester content action
   */
  @debugAction({
    answers: {
      type: 'select',
      options: [
        { label: 'Correct', value: 'correct' },
        { label: 'Incorrect', value: 'incorrect' },
      ],
      values: answerValues,
    },
  })
  @action
  async saveSkillsQuizResult({ answers, uuid: attemptUuid }: SkillsQuizResult) {
    const { lessonInPrecinct } = this
    let assignment = null

    if (
      this.assignments.currentTask?.__typename === 'AssignmentTaskLesson' &&
      this.assignments.canCompleteLessonAssignmentTask(
        this.lessonId,
        this.activityId,
      )
    ) {
      assignment = {
        assignmentTaskId: parseInt(this.assignments.currentTask.id, 10),
        assignmentUuid: this.assignments.currentTask.assignmentUuid,
      }
    }

    try {
      const result = await this.saveSkillsQuizResultMutation.current.mutate({
        variables: {
          input: {
            answers,
            lessonInPrecinct,
            attemptUuid,
            assignment,
          },
        },
      })

      if (result) {
        const {
          readingEndOfLessonQuizResultCreate: {
            readingEndOfLessonQuizResult: {
              attempt,
              autoPassed,
              passed,
              eggsEarned,
            },
          },
        } = result

        return { attempt, autoPassed, lessonInPrecinct, passed, eggsEarned }
      }

      return { passed: false, lessonInPrecinct }
    } catch (error) {
      throw contentError('SaveFailed', error)
    }
  }

  /**
   * error handler action
   * This is a Jester content action
   * @param UnhandledErrorMessage
   */
  @action
  unhandledError({ error }: { error: Partial<ErrorLike> }) {
    this.errorHandler.handleContentUnhandledError(error)
  }
}

declare module '@ember/controller' {
  interface Registry {
    'lessons/lesson/activity': LessonsLessonActivityController
  }
}
