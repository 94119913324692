import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'

export default class ReaderBookPageController extends Controller {
  @service
  locationTracker

  @service
  router

  @action
  next() {
    this.backToQuiz()
  }

  @action
  backToQuiz() {
    const { route, params } = this.locationTracker.getPreviousRoute()
    this.router.transitionTo(route, ...params)
  }
}
