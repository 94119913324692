import { hbs } from 'ember-cli-htmlbars';
const __COLOCATED_TEMPLATE__ = hbs("<div {{this.registerTarget}}>\n  {{#if this.target}}\n    {{#in-element this.target}}\n      <div\n        tabindex=\"-1\"\n        class=\"pointer-events-auto aria-hidden:hidden\"\n        data-open={{this.open}}\n        {{dialog open=this.open show=this.show hide=this.hide}}\n        ...attributes\n      >\n        {{yield (hash show=this.show hide=this.hide)}}\n      </div>\n    {{/in-element}}\n  {{/if}}\n</div>", {"contents":"<div {{this.registerTarget}}>\n  {{#if this.target}}\n    {{#in-element this.target}}\n      <div\n        tabindex=\"-1\"\n        class=\"pointer-events-auto aria-hidden:hidden\"\n        data-open={{this.open}}\n        {{dialog open=this.open show=this.show hide=this.hide}}\n        ...attributes\n      >\n        {{yield (hash show=this.show hide=this.hide)}}\n      </div>\n    {{/in-element}}\n  {{/if}}\n</div>","moduleName":"re-client/components/dialog.hbs","parseOptions":{"srcName":"re-client/components/dialog.hbs"}});
import Component from '@glimmer/component'
import { tracked } from '@glimmer/tracking'
import { action } from '@ember/object'
import { modifier } from 'ember-modifier'

interface DialogSignature {
  Args: {
    open?: boolean
    onShow?: (event?: Event) => void
    onHide?: (event?: Event) => void
  }
  Blocks: {
    default: []
  }
  Element: HTMLElement
}

const scrollLockClasses = ['overflow-hidden', 'pointer-events-none']

export default class DialogComponent extends Component<DialogSignature> {
  /**
   * Used by the in-element modifier to render the dialog
   *
   * Allows the application template to define where the dialog should be rendered.
   */
  @tracked target: HTMLElement | null = null

  get state() {
    if (this.args.open) {
      return 'open'
    }

    return 'closed'
  }

  get open() {
    return this.state === 'open'
  }

  @action
  show(event?: Event) {
    this.addScrollLock()
    this.args.onShow?.(event)
  }

  @action
  hide(event?: Event) {
    this.removeScrollLock()
    this.args.onHide?.(event)
  }

  addScrollLock() {
    document.body.classList.add(...scrollLockClasses)
  }

  removeScrollLock() {
    document.body.classList.remove(...scrollLockClasses)
  }

  /**
   * Registers the target element to append the dialog into.
   */
  registerTarget = modifier(() => {
    this.target = document.querySelector('#dialog-target')
  })

  override willDestroy(): void {
    super.willDestroy()
    this.removeScrollLock()
  }
}
