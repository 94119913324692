import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type UserService from 're-client/services/user'
import type { FeatureService } from '@blakeelearning/features'
import { queryManager } from 'ember-apollo-client'
import type { QueryManager } from 'ember-apollo-client'
import { graphql } from 're-client/graphql'
import type { DocumentType } from 're-client/graphql'

export const StudentMyAwardsFragment = graphql(/* GraphQL */ `
  fragment StudentMyAwardsFragment on Student {
    id
    badges {
      id
      lesson
      colour
    }
    certificates {
      id
      createdAt
      map
      url
    }
    pendingCertificate {
      id
      map
    }
    ...StudentProgressFragment
  }
`)

export const getMyAwardsQueryDocument = graphql(/* GraphQL */ `
  query GetMyAwards {
    student {
      ...StudentMyAwardsFragment
    }
  }
`)

type StudentMyAwards = DocumentType<typeof StudentMyAwardsFragment>

interface Variables {
  badges: StudentMyAwards['badges'] | undefined
  certificates: StudentMyAwards['certificates'] | undefined
  currentMap: number | undefined
  pendingCertificate: StudentMyAwards['pendingCertificate'] | undefined
}

interface ModelForRoute {
  variables: Variables
  manifests: string[][]
}

export default class AwardsRoute extends Route {
  @queryManager() declare apollo: QueryManager

  @service declare user: UserService

  @service declare features: FeatureService

  override buildRouteInfoMetadata() {
    return {
      willHideLoadingScreenManually: true,
    }
  }

  override async model(): Promise<ModelForRoute> {
    const { student } = await this.apollo.query({
      query: getMyAwardsQueryDocument,
      fetchPolicy: 'cache-first',
    })

    const manifests = [['index', 'my-awards']]

    return {
      variables: {
        badges: student?.badges,
        certificates: student?.certificates,
        currentMap: student?.progress.lessons.currentMap,
        pendingCertificate: student?.pendingCertificate,
      },
      manifests,
    }
  }
}
