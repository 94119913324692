import { get } from '@ember/object'
import { typeOf } from '@ember/utils'

/**
 * Function to deal with default values better then getWithDefault does
 * @param valueToTest
 * @param getPropertyName
 * @param defaultValue
 * @returns {*}
 */
export function getOrDefault(
  valueToTest,
  getPropertyName,
  defaultValue = undefined,
) {
  if (typeOf(valueToTest) === 'function') valueToTest = valueToTest()
  if (valueToTest === undefined || valueToTest === null) return defaultValue
  return get(valueToTest, getPropertyName) || defaultValue
}
