import { modifier } from 'ember-modifier'
import gsap from 'gsap'

interface NavShopAnimationSignature {
  Element: HTMLElement
}

export default modifier<NavShopAnimationSignature>(
  function navShopAnimation(element) {
    const image = element.querySelector('svg')

    const timeline = gsap.timeline({ delay: 5 })
    timeline.to(image, {
      duration: 1,
      transformOrigin: '50% 100%',
      y: 0,
      scaleX: 1.2,
      scaleY: 1.1,
      ease: 'elastic.in',
      repeat: 10,
      yoyo: true,
    })
    timeline.to(image, {
      duration: 1,
      scaleX: 1,
      scaleY: 1,
      ease: 'elastic.out',
    })

    return () => {
      timeline.kill()
    }
  },
)
