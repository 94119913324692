import Service from '@ember/service'
import { tracked } from '@glimmer/tracking'
import { queryManager } from 'ember-apollo-client'
import type { QueryManager } from 'ember-apollo-client'
import { graphql } from 're-client/graphql'
import type { DocumentType } from 're-client/graphql'
import type {
  QuizAnswerInput,
  ReadingGoalsQuizAreaEnum,
  ReadingGoalsQuizLevelEnum,
} from 're-client/graphql/graphql'
import { useMutation } from 're-client/resources/mutation'

graphql(`
  fragment ReadingGoalsQuizFragment on ReadingGoalsQuiz {
    area
    badgeReward
    id
    level
    name
  }
`)

const _ReadingGoalsQuizSummaryFragment = graphql(`
  fragment ReadingGoalsQuizSummaryFragment on ReadingGoalsQuizSummary {
    attemptsThisWeek
    eggsReward
    id
    passed
    secondsToPass
    timestamp
  }
`)

graphql(`
  fragment QuizAnswerFragment on QuizAnswer {
    correct
    correctAnswer
    studentAnswer
  }
`)

graphql(`
  fragment ReadingGoalsQuizResultFragment on ReadingGoalsQuizResult {
    answers {
      ...QuizAnswerFragment
    }
    attemptUuid
    createdAt
    eggsReward
    id
    level
    passed
    quizId
    score
  }
`)

const readingGoalsQuizzesQueryDocument = graphql(`
  query GetReadingGoalsQuizzes {
    readingGoalsQuizzes {
      ...ReadingGoalsQuizFragment
    }
  }
`)

const readingGoalsQuizRecordQueryDocument = graphql(`
  query GetReadingGoalsQuizRecord {
    student {
      readingGoalsMinimumLevel
      readingGoalsQuizSecondsToNextWeek
      readingGoalsQuizSummaries {
        ...ReadingGoalsQuizSummaryFragment
      }
    }
  }
`)

const readingGoalsQuizResultCreateMutationDocument = graphql(`
  mutation CreateReadingGoalsQuizResult(
    $input: ReadingGoalsQuizResultCreateInput!
  ) {
    readingGoalsQuizResultCreate(input: $input) {
      readingGoalsQuizResult {
        passed
        eggsReward
      }
      student {
        id
        eggs
        name @client
        readingGoalsQuizSummaries {
          ...ReadingGoalsQuizSummaryFragment
        }
      }
    }
  }
`)

type ReadingGoalsQuizSummary = DocumentType<
  typeof _ReadingGoalsQuizSummaryFragment
>

interface CreateReadingGoalsQuizResultOptions {
  answers: QuizAnswerInput[]
  attemptUuid: string
  quizId: string
}

export interface ReadingGoalsQuizTile {
  area: ReadingGoalsQuizAreaEnum
  badgeReward: string
  id: string
  level: ReadingGoalsQuizLevelEnum
  name: string
  attemptsThisWeek: number
  eggsReward?: number | null
  passed: boolean
  secondsToPass?: number | null
  timestamp: string
}

export default class ReadingGoalsService extends Service {
  @queryManager() declare apollo: QueryManager

  readingGoalsQuizResultCreateMutation = useMutation(
    this,
    readingGoalsQuizResultCreateMutationDocument,
  )

  @tracked lastCompletedQuizId = ''

  async getQuizzes() {
    const { readingGoalsQuizzes } = await this.apollo.query({
      query: readingGoalsQuizzesQueryDocument,
    })

    return readingGoalsQuizzes
  }

  async getRecord() {
    const { student } = await this.apollo.query({
      query: readingGoalsQuizRecordQueryDocument,
    })

    const summaries = new Map<string, ReadingGoalsQuizSummary>()

    if (student?.readingGoalsQuizSummaries) {
      for (const item of student.readingGoalsQuizSummaries) {
        summaries.set(item.id, item)
      }
    }

    return {
      summaries,
      minimumLevel: student?.readingGoalsMinimumLevel,
      secondsToNextWeek: student?.readingGoalsQuizSecondsToNextWeek,
    }
  }

  async createQuizResult({
    answers,
    attemptUuid,
    quizId,
  }: CreateReadingGoalsQuizResultOptions) {
    return this.readingGoalsQuizResultCreateMutation.current.mutate({
      variables: {
        input: {
          answers,
          attemptUuid,
          quizId,
        },
      },
    })
  }
}

declare module '@ember/service' {
  interface Registry {
    'reading-goals': ReadingGoalsService
  }
}
