import Service, { inject as service } from '@ember/service'
import { tracked } from '@glimmer/tracking'

/**
 * @class OfflineScreenService
 */
export default class OfflineScreenService extends Service {
  @tracked onlineCalled = false

  @tracked shouldDisplay = false

  @service('device/network')
  network

  /**
   * When the network status is offline (determined bye the offline/online events fired)
   * and the offlineScreen is displaying then we can
   * show the offline screen. The offlineScreen service prop is set
   * when errors certain error conditions are met, mainly done in the applications route error action
   * or for one special case in the page-loader component.
   *
   * @type boolean
   */
  get canShowOfflineScreen() {
    const { isOnline, isOffline } = this.network.status

    if (this.onlineCalled && isOnline && this.shouldDisplay) {
      return this._reloadWindow()
    }

    return isOffline && this.shouldDisplay
  }

  /**
   * Setter for shouldDisplay. indicates if the offline screen should be shown.
   */
  display() {
    this.shouldDisplay = true
  }

  _reloadWindow() {
    window.location.reload()
  }
}
