/* eslint-disable @typescript-eslint/no-unnecessary-condition */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-unsafe-member-access */
/* eslint-disable @typescript-eslint/no-unsafe-assignment */

import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import config from 're-client/config/environment'
import { get, uniqBy } from 'lodash'
import type StudentProgressService from 're-client/services/student-progress'
import type ActivityService from 're-client/services/activity'
import type UserService from 're-client/services/user'
import type UrlMakerService from 're-client/services/url-maker'
import type RouterService from '@ember/routing/router-service'
import type { Activity } from 're-client/services/activity'
import { Framework } from 're-client/graphql/graphql'
import type LessonsService from 're-client/services/lessons'
import type CaperLoaderService from 're-client/services/caper-loader'
import type JesterLoaderService from 're-client/services/jester-loader'
import type AssignmentsService from 're-client/services/assignments'

export interface SideBarData {
  id: number
  thumbnail: string | undefined
}

interface Params extends Record<string, unknown> {
  lesson_id: string
}

interface LessonsLessonRouteModel {
  activitiesForLesson: Activity[]
  lessonId: string
  manifestBundles: Map<Framework, unknown>
  sideBarData: SideBarData[]
}

export default class LessonsLessonRoute extends Route<
  LessonsLessonRouteModel,
  Params
> {
  @service declare studentProgress: StudentProgressService

  @service('lessons') declare policy: LessonsService

  @service declare assignments: AssignmentsService

  @service declare activity: ActivityService

  @service() declare caperLoader: CaperLoaderService

  @service() declare jesterLoader: JesterLoaderService

  @service declare user: UserService

  @service declare urlMaker: UrlMakerService

  @service declare router: RouterService

  async getManifestBundle(activity: Activity) {
    let loader
    let manifestBundleUrl

    if (activity.framework === Framework.Caper) {
      loader = this.caperLoader.loader
      manifestBundleUrl = this.urlMaker.urlForManifestBundle(
        activity.manifestBundle,
      )
    } else {
      loader = this.jesterLoader.loader
      manifestBundleUrl = loader.urlForManifestBundle(activity.manifestBundle)
    }

    const manifestBundle = await loader.fetchManifest(manifestBundleUrl)
    return manifestBundle
  }

  async fetchManifestBundles(activitiesForLesson: Activity[]) {
    const uniqueActivitiesByFramework = uniqBy(activitiesForLesson, 'framework')

    const manifestBundles = await Promise.all(
      uniqueActivitiesByFramework.map((activity) =>
        this.getManifestBundle(activity),
      ),
    )

    return new Map(
      uniqueActivitiesByFramework.map(({ framework }, index) => [
        framework,
        manifestBundles[index],
      ]),
    )
  }

  getActivityThumbnail(
    framework: Framework,
    manifestKey: string[],
    manifestBundles: Map<Framework, unknown>,
  ) {
    switch (framework) {
      case Framework.Jester: {
        const thumb = get(manifestBundles.get(Framework.Jester), manifestKey)
          ?.assets?._lazy?.images[0]
        if (typeof thumb === 'string') return thumb
        if (thumb && 'assetPath' in thumb)
          return new URL(
            thumb.assetPath,
            config.contentLoader.jester.assetHosts.assets,
          ).toString()
        return
      }

      case Framework.Caper: {
        const thumb = get(
          manifestBundles.get(Framework.Caper),
          manifestKey,
        )?.thumb
        if (typeof thumb === 'string') return thumb
        if (thumb && 'assetPath' in thumb)
          return new URL(
            thumb.assetPath,
            config.contentLoader.caper.assetHosts.assets,
          ).toString()
        return
      }
    }
  }

  override beforeModel() {
    if (this.assignments.hasTask) {
      return
    }

    if (this.studentProgress.showLessonsPlacementTest) {
      void this.router.transitionTo('lessons.placement-test')
    }
  }

  override async model(params: Params) {
    const lessonId = params.lesson_id
    this.policy.setCurrentLesson(lessonId)

    const activitiesForLesson =
      this.activity.getReadingActivitiesByLessonId(lessonId)

    let manifestBundles = new Map<Framework, unknown>()
    let sideBarData: SideBarData[] = []
    if (this.activity.hasReadingActivitiesByLessonId(lessonId)) {
      manifestBundles = await this.fetchManifestBundles(activitiesForLesson)
      sideBarData = activitiesForLesson.map(
        ({ activityInLesson, framework, manifestKey }) => ({
          id: activityInLesson,
          thumbnail: this.getActivityThumbnail(
            framework,
            manifestKey,
            manifestBundles,
          ),
        }),
      )
    } else {
      void this.router.replaceWith(
        'lessons.lesson.index',
        this.studentProgress.lessonsCurrentLessonNumber,
      )
    }

    return { activitiesForLesson, manifestBundles, sideBarData, lessonId }
  }

  override afterModel(model: LessonsLessonRouteModel) {
    if (model.lessonId !== this.policy.currentLesson) {
      void this.router.replaceWith('lessons.lesson', this.policy.currentLesson)
    }
  }
}
