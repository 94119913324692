import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import { action } from '@ember/object'

export default class ReaderBookController extends Controller {
  @service
  locationTracker

  @service
  operations

  @service
  router

  @service
  user

  @service
  store

  @service
  assignments

  get student() {
    return this.user.student
  }

  get studentContext() {
    const { currentTask } = this.assignments

    if (currentTask?.__typename === 'AssignmentTaskLesson') {
      return {
        precinct: 'lessons',
        remoteId: this.student?.remoteId,
        taskId: currentTask.id,
        assignmentUuid: currentTask.assignmentUuid,
      }
    }

    return {
      precinct: 'lessons',
      remoteId: this.student?.remoteId,
    }
  }

  @action
  next() {
    const { route, params } = this.locationTracker.getPreviousRoute()
    this.router.transitionTo(route, ...params)
  }

  @action
  resultVideo(videoTitle) {
    this.router.transitionTo('reader.result', videoTitle)
  }

  @action
  async saveBookQuizScore(code, totalCount, correctCount) {
    const {
      eligible,
      reward: { milestone, eggs, passed },
    } = await this.operations.completeBookQuiz(
      this.studentContext,
      code,
      correctCount,
      totalCount,
    )
    if (passed) {
      this.user.incrementEggs(eggs)

      // NOTE: Once user has successfully read a book & passed the quiz we know
      // this book should be shown on the bookshelf, hence instead of
      // reloading the bookshelf route everytime, it is better to do
      // it here. reload() is more reliable than { reload: true }
      // findRecord will fetch cached data & reload() will refetch the request
      const bookshelf = await this.store.findRecord('bookshelf', 'bookshelf')
      await bookshelf.reload()
    }
    await this.assignments.fetch()
    return { passed, eligible, milestone }
  }

  @action
  async saveChapterQuizScore(code, chapter, totalCount, correctCount) {
    const { passed, eligible, eggs } =
      await this.operations.completeChapterQuiz(
        this.studentContext,
        code,
        chapter,
        correctCount,
        totalCount,
      )
    if (passed) {
      this.user.incrementEggs(eggs)
    }

    await this.assignments.fetch()
    return { passed, eligible }
  }
}
