import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import config from 're-client/config/environment'
import type UserService from 're-client/services/user'
import ky from 'ky'
import prefetch from 're-client/utils/prefetch'

interface SaveAvatarOptions {
  DNA: string
  image: string
}

const {
  APP: { apiNamespace, apiEndpoint },
} = config

let prefixUrl = `/${apiNamespace}`

if (apiEndpoint) {
  prefixUrl = new URL(apiNamespace, apiEndpoint).href
}

export default class ChangingRoomController extends Controller {
  @service
  declare user: UserService

  get student() {
    return this.user.student
  }

  @action
  async saveAvatar({ DNA, image }: SaveAvatarOptions) {
    await ky.put('avatar', {
      credentials: 'include',
      json: { dna: DNA, image },
      prefixUrl,
    })

    // could probably just update the apollo cache here with the response from the ajax request
    await this.user.fetch()
    prefetch(this.user.student.avatarUrl)
  }
}
