import Controller from '@ember/controller'
import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import type { CertificateContentActions } from '@blakeelearning/content-specs-readingeggs'
import { certificateContentSpec } from '@blakeelearning/content-specs-readingeggs'
import type { ContentSpec } from '@blakeelearning/messenger'
import type RouterService from '@ember/routing/router-service'
import type { ModelFor } from 're-client/utils/route-model'
import { debug } from '@ember/debug'
import { debugAction, debugValue } from 're-client/utils/debug'
import { base64url } from 'rfc4648'
import { tracked } from '@glimmer/tracking'
import type DeviceDetection from '@blakeelearning/device/device/detection/service'
import ky, { HTTPError } from 'ky'
import type UserService from 're-client/services/user'
import type ErrorHandlerService from 're-client/services/error-handler'
import { contentError, isNetworkError } from 're-client/utils/errors'
import type LoadingUiService from 're-client/services/loading-ui'
import config from 're-client/config/environment'
import printImage from 're-client/utils/print-image'
import prefetch from 're-client/utils/prefetch'
import type LessonsCertificateRoute from 're-client/routes/lessons/certificate'
import type CertificateService from 're-client/services/certificate'

export interface SaveCertificateMessage {
  svgString: string
  width: number
  height: number
}

export interface SaveCertificateResult {
  savedCertificateUrl: string
}

export interface PrintMessage {
  certificateUrl: string
}

interface ProblemDetails {
  type: string
  title: string
  status: number
  instance?: string
  detail?: string
  [key: string]: unknown
}

const { lastMap: lastLessonsMap } = config.studentProgress.progress.lessons

export default class LessonsCertificateController
  extends Controller
  implements CertificateContentActions
{
  @service declare certificate: CertificateService

  @debugValue()
  declare model: ModelFor<LessonsCertificateRoute>

  @service declare router: RouterService

  @service('device/detection') declare deviceDetection: DeviceDetection

  @service declare user: UserService

  @service declare errorHandler: ErrorHandlerService

  @service declare loadingUi: LoadingUiService

  get contentSpec(): ContentSpec {
    return certificateContentSpec
  }

  get map() {
    return this.model.map
  }

  get isLastLessonsMap() {
    return this.map === lastLessonsMap
  }

  get pendingCertificateId() {
    return this.model.pendingCertificateId
  }

  @action
  unhandledError({ error }: { error: { name?: string; message?: string } }) {
    debug(`[CertificateController] unhandledError()`)
    this.errorHandler.handleContentUnhandledError(error)
  }

  @action
  readyForUserInput() {
    debug(`[CertificateController] readyForUserInput()`)
    this.loadingUi.hide() // clear the loading screen
  }

  @debugAction({
    svgString: { type: 'text' },
    width: { type: 'number', value: '1240' },
    height: { type: 'number', value: '1647' },
  })
  @action
  async saveCertificate({
    svgString,
    width,
    height,
  }: SaveCertificateMessage): Promise<SaveCertificateResult> {
    debug(`[CertificateController] saveCertificate()`)

    try {
      await this._uploadSvgWithRetries(svgString, 2)

      const result = await this.certificate.saveCertificate({
        pendingCertificateId: this.pendingCertificateId,
        width,
        height,
      })

      const savedCertificateUrl = result?.certificateSave.certificate.url ?? ''

      prefetch(savedCertificateUrl)

      this.savedCertificateUrl = savedCertificateUrl // Update this tracked property for debug mode

      return { savedCertificateUrl }
    } catch (error) {
      if (isNetworkError(error)) throw contentError('NetworkError', error)
      throw contentError('SaveFailed', error)
    }
  }

  @debugAction()
  @action
  goHome() {
    debug(`[CertificateController] goHome()`)
    void this.router.transitionTo('/')
  }

  @debugAction()
  @action
  goNext() {
    debug(`[CertificateController] goNext()`)
    if (this.isLastLessonsMap) {
      void this.router.transitionTo('lessons.finished-re-lessons')
      return
    }
    void this.router.transitionTo('lessons.map.next')
  }

  @debugAction({
    certificateUrl: {
      type: 'text',
      value:
        'https://certificates.blake-staging.com/re/legacy/WzEsImdvbGQiLDEwMCwiU2FtcGxlIEMuIiwxNjc5NDYyNzc0NTQ1XQ.svg', // TODO: this value is temporary to assist with native app testing
    },
  })
  @action
  async print({ certificateUrl }: PrintMessage): Promise<void> {
    debug(`[CertificateController] print()`)

    if (this.deviceDetection.isNativeMobile) {
      const nativeMobilePrintUrl = new URL(certificateUrl)
      // The prescence of this param will result in an SVG element with `width=100% height=auto` attributes. The mobile apps require this for print layout.
      nativeMobilePrintUrl.searchParams.set('fitpage', '')
      // Mobile apps will intercept these calls and take care of printing from there.
      window.open(nativeMobilePrintUrl.href, '_blank')
      return
    }
    try {
      await printImage(certificateUrl)
    } catch (error) {
      throw contentError('PrintFailed', error)
    }
  }

  /**
   * Runs a query for a specific pending certificate's uploadUrl. The result is not cached since the url will expire shortly after we receive it.
   * If we do not get back a pending certificate, it means the student must have already sucessfully uploaded a certificate and our state is out of sync.
   * In this case we trigger a refetch of the GetStudent query to clear our bad state and redirect the student to the index route.
   * @returns upload url
   */
  async _fetchUploadUrl() {
    const { getPendingCertificate: pendingCertificate } =
      await this.certificate.getPendingCertificateUploadUrl(
        this.pendingCertificateId,
      )

    if (pendingCertificate?.__typename !== 'PendingCertificate') {
      await this.user.fetch()
      void this.router.transitionTo('/')
      throw new Error(
        `No PendingCertificate with id: ${this.pendingCertificateId}`,
      )
    }

    return pendingCertificate.uploadUrl
  }

  /**
   * A recursive function that will attempt to upload an SVG string.
   * On each call it will fetch a fresh uploadUrl and then use it to send a PUT request with the SVG string.
   * ky will retry 3 times for network error and also for some 4xx and 5xx HTTP errors.
   * If the upload fails because the uploadUrl has expired and we still have expiry retries remaining the function will be triggered again.
   * @param svgString - The SVG string to upload.
   * @param expiryRetries - The number of times to retry the upload if the uploadUrl has expired. Defaults to 2.
   * @returns A Promise that resolves with the response from the server if the upload is successful.
   * @throws If the upload fails after all retries have been exhausted or if an unknown error occurs.
   */
  async _uploadSvgWithRetries(
    svgString: string,
    expiryRetries = 2,
  ): Promise<Response> {
    const uploadUrl = await this._fetchUploadUrl()
    try {
      const response = await ky.put(uploadUrl, {
        headers: {
          'content-type': 'image/svg+xml',
        },
        body: svgString,
        retry: {
          limit: 3,
          methods: ['put'],
          statusCodes: [408, 429, 500, 502, 503, 504], // Omitting 413 (Payload Too Large) from the ky defaults. We also do not retry 410 (Gone) as this indicates an expired url which explicitly handled in the catch block below.
        },
      })
      return response
    } catch (error) {
      if (
        error instanceof HTTPError &&
        error.response.headers
          .get('content-type')
          ?.match(/application\/problem\+json/)
      ) {
        const problem = (await error.response.json()) as ProblemDetails
        if (!!/expired_url/.exec(problem.type) && expiryRetries > 0) {
          debug(
            `${problem.detail ?? 'URL Expired'} Retrying ${expiryRetries} more times...`,
          )
          return this._uploadSvgWithRetries(svgString, expiryRetries - 1)
        }
      }

      throw error
    }
  }

  /**
   * ###########################################
   * Everything below here is debug mode related
   * ###########################################
   */

  /**
   *  ### For debug mode
   *  Crafts a URL which will return a default certificate in its 'original' (pre-hydrated) format
   */
  get defaultCertificateUrl() {
    const {
      variables: {
        mapResult: { colour, percentage, date },
        studentName,
      },
    } = this.model
    const certData = [
      this.map,
      colour,
      percentage,
      studentName,
      parseInt(date, 10),
    ]
    const base64Url = base64url.stringify(
      new TextEncoder().encode(JSON.stringify(certData)),
      { pad: false },
    )
    const url = new URL(
      `re/legacy/${base64Url}.svg`,
      `https://certificates.blake-staging.com`,
    )
    url.searchParams.set('original', 'true')
    return url.href
  }

  /**
   *  ### For debug mode
   *  This will fetch a default certificate, extract it's text (an svg string) and stores it in the tracked `defaultCertificateSvg`
   */
  @debugAction()
  @action
  async fetchDefaultCertificateSvg() {
    const res = await fetch(this.defaultCertificateUrl)
    const svgString = await res.text()
    this.defaultCertificateSvg = svgString
  }

  /**
   *  ### For debug mode
   *  This will stores and display the result of fetching a default certificate so that it can be copy and pasted into the saveCertificate() action
   */
  @debugValue()
  @tracked
  defaultCertificateSvg?: string

  /**
   *  ### For debug mode
   *  This will display the URL we get as a result of saving the certificate so that it can be copy and pasted into the print() action
   */
  @debugValue()
  @tracked
  savedCertificateUrl?: string
}
