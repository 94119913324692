import type Operations from '@blakeelearning/student-operations/operations/service'
import Controller from '@ember/controller'
import { action } from '@ember/object'
import type RouterService from '@ember/routing/router-service'
import { inject as service } from '@ember/service'
import config from 're-client/config/environment'
import type StorylandsLessonActivityRoute from 're-client/routes/storylands/lesson/activity'
import type ActivitySidebarService from 're-client/services/activity-sidebar'
import type AssignmentsService from 're-client/services/assignments'
import type DebugModeService from 're-client/services/debug-mode'
import type LocationTrackerService from 're-client/services/location-tracker'
import type StorylandsService from 're-client/services/storylands'
import type StudentProgressService from 're-client/services/student-progress'
import type UserService from 're-client/services/user'
import camelizeKeys from 're-client/utils/camelize-keys'
import { debugAction } from 're-client/utils/debug'
import { calculateMap } from 're-client/utils/progress-tools'
import type { ModelFor } from 're-client/utils/route-model'

interface QuizResults {
  correct: number
  total: number
  incorrect_answers: Record<string, string>
  correct_answers: string[]
}

/**
 * The storylands activity controller
 *
 * @class StorylandsController
 */
export default class StorylandsLessonActivityController extends Controller {
  @service
  declare operations: Operations

  @service
  declare router: RouterService

  @service
  declare studentProgress: StudentProgressService

  @service
  declare locationTracker: LocationTrackerService

  @service
  declare assignments: AssignmentsService

  @service
  declare debugMode: DebugModeService

  @service('storylands')
  declare policy: StorylandsService

  @service
  declare user: UserService

  @service
  declare activitySidebar: ActivitySidebarService

  declare model: ModelFor<StorylandsLessonActivityRoute>

  declare interactive: {
    callInteractionMethod(method: string, ...args: unknown[]): void
  }

  get isAssignmentMode() {
    return this.model?.isAssignmentMode
  }

  get currentPositionTitle() {
    return this.isAssignmentMode
      ? this.lessonId
      : this.studentProgress.storylandsCurrentLesson.toString()
  }

  get currentMap() {
    return this.studentProgress.storylands.currentMap
  }

  get lessonId() {
    return Number(this.model?.lesson.id)
  }

  get activityId() {
    return Number(this.model?.activityId)
  }

  get activityIndex() {
    return this.activityId - 1
  }

  get nextActivity() {
    return this.model?.lesson.nextActivity(this.activityIndex)
  }

  get studentContext() {
    let taskId
    let assignmentUuid

    if (
      this.assignments.canCompleteStorylandsAssignmentTask(
        this.lessonId,
        this.activityId,
      )
    ) {
      taskId = this.assignments.currentTask?.id
      assignmentUuid = this.assignments.currentTask?.assignmentUuid
    }

    return {
      product: config.APP.product,
      precinct: 'clinker_castle',
      remoteId: this.user.student.remoteId,
      taskId,
      assignmentUuid,
    }
  }

  get mapId() {
    return calculateMap(
      this.lessonId,
      config.studentProgress.progress.storylands.lessonsPerMap,
    )
  }

  @action
  changeActivity(newActivityID: string | number) {
    if (this.policy.canPlayLessonActivity(this.lessonId, newActivityID)) {
      this.router.transitionTo('storylands.lesson.activity', newActivityID)
    }
  }

  @action
  async next() {
    if (this.nextActivity) {
      this.router.transitionTo(
        'storylands.lesson.activity',
        this.nextActivity.id,
      )
    } else {
      await this.studentProgress.fetchProgress()
      if (
        this.currentMap > config.studentProgress.progress.storylands.lastMap
      ) {
        this.router.transitionTo('storylands.finished-re-storylands')
      } else {
        this.goToMap()
      }
    }
  }

  @action
  @debugAction({
    amount: {
      type: 'number',
      value: '1',
    },
  })
  incrementScore(args: { amount: number } | number = 1) {
    if (typeof args === 'number') {
      this.user.incrementEggs(args)
    } else {
      this.activitySidebar.open(args.amount)
    }
  }

  @action
  // eslint-disable-next-line @typescript-eslint/require-await -- caper activity needs to be updated
  async reportQuizState() {
    return this.locationTracker.getStorylandsQuizState()
  }

  @action
  setQuizState(state: unknown) {
    this.locationTracker.setStorylandsQuizState(state)
  }

  @action
  clearQuizState() {
    this.locationTracker.setStorylandsQuizState(null)
  }

  @action
  playBook(bookId: string | number, page: string | number) {
    this.router.transitionTo('reader.book.page', bookId, page)
  }

  @action
  goToHouse() {
    this.router.transitionTo('house')
  }

  @action
  goToChangeRoom() {
    this.router.transitionTo('changing-room')
  }

  @action
  async saveProgress() {
    const serverResponse = await this.operations.completeActivity(
      this.studentContext,
      this.lessonId,
      this.activityId,
    )
    await this.studentProgress.fetchProgress()
    await this.assignments.fetch()

    const data = { reward: { eggs: 0 }, ...camelizeKeys(serverResponse) }
    this.interactive.callInteractionMethod('nextable', data)
  }

  @action
  async saveQuizResult(results: QuizResults) {
    const response = await this.operations.completeLessonQuiz({
      context: this.studentContext,
      lesson: this.lessonId,
      activity: this.activityId,
      correct: results.correct,
      total: results.total,
      incorrectAnswers: results.incorrect_answers,
      correctAnswers: results.correct_answers,
    })
    await this.studentProgress.fetchProgress()
    await this.assignments.fetch()
    const data = { reward: { eggs: 0 }, ...camelizeKeys(response) }
    this.interactive.callInteractionMethod('nextable', data)
  }

  /**
   * Transition to the map for this storylands lesson
   *
   * @instance
   * @memberOf LessonActivityController
   */
  @action
  goToMap() {
    this.router.transitionTo('storylands.map', this.currentMap)
  }

  @action
  @debugAction()
  async completeActivity() {
    await this.saveProgress()
    await this.next()
  }

  @action
  @debugAction({
    results: {
      type: 'select',
      options: [
        { label: 'Pass Quiz', value: 'pass' },
        { label: 'Fail Quiz', value: 'fail' },
      ],
      values: {
        pass: {
          correct: 10,
          total: 10,
          correct_answers: [
            'debug1',
            'debug2',
            'debug3',
            'debug4',
            'debug5',
            'debug6',
            'debug7',
            'debug8',
            'debug9',
            'debug10',
          ],
          incorrect_answers: {},
        },
        fail: {
          correct: 0,
          total: 10,
          correct_answers: [],
          incorrect_answers: {
            debug1: 'wrong 1',
            debug2: 'wrong 2',
            debug3: 'wrong 3',
            debug4: 'wrong 4',
            debug5: 'wrong 5',
            debug6: 'wrong 6',
            debug7: 'wrong 7',
            debug8: 'wrong 8',
            debug9: 'wrong 9',
            debug10: 'wrong 10',
          },
        },
      },
    },
  })
  async completeQuiz({ results }: { results: QuizResults }) {
    await this.saveQuizResult(results)
    await this.next()
  }
}

declare module '@ember/controller' {
  interface Registry {
    'storylands/lesson/activity': StorylandsLessonActivityController
  }
}
