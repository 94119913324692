import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type RouterService from '@ember/routing/router-service'
import type StudentProgressService from 're-client/services/student-progress'
import type StorylandsService from 're-client/services/storylands'
import type AssignmentsService from 're-client/services/assignments'
import type LocationTrackerService from 're-client/services/location-tracker'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UserService from 're-client/services/user'
import type { ModelFor } from 're-client/utils/route-model'
import type StorylandsLessonRoute from 're-client/routes/storylands/lesson'
import type { StorylandsLessonRouteModel } from 're-client/routes/storylands/lesson'
import type Transition from '@ember/routing/transition'

interface RouteModel {
  interactiveConfig: unknown
  lesson: StorylandsLessonRouteModel
  activityId: number
  sideBarActivityData: unknown[]
  isAssignmentMode: boolean
}

interface RouteParams {
  activity_id: string
}

/**
 * The lesson activity route
 *
 * @class StorylandsLessonActivityRoute
 */
export default class StorylandsLessonActivityRoute extends Route<
  RouteModel | undefined,
  RouteParams
> {
  currentActivityId: number | null = null

  @service
  declare router: RouterService

  @service
  declare studentProgress: StudentProgressService

  @service('storylands')
  declare policy: StorylandsService

  @service
  declare assignments: AssignmentsService

  @service
  declare locationTracker: LocationTrackerService

  @service()
  declare caperLoader: CaperLoaderService

  @service
  declare user: UserService

  override async model(params: RouteParams): Promise<RouteModel | undefined> {
    const lesson = this.modelFor(
      'storylands.lesson',
    ) as ModelFor<StorylandsLessonRoute>
    const activityId = parseInt(params.activity_id, 10)
    this.currentActivityId = activityId

    if (!lesson || !this.ensureAccessAllowed(lesson, activityId)) return

    const isAssignment = this.assignments.canCompleteStorylandsAssignmentTask(
      lesson.id,
      activityId,
    )

    // This activity's manifest has already been loaded in the storylands.lesson
    // parent route, so we pass the preloaded manifest to the interactiveLoader.
    const manifests = [lesson.findActivityManifest(activityId)]
    const variables = {
      student: this.user.student,
      isAssignment,
    }

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    const sideBarActivityData = lesson.activities.map((activity) => {
      let status = 'locked'

      if (activity.id === activityId) {
        status = 'unlocked'
      } else if (this.policy.canPlayLessonActivity(lesson.id, activity.id)) {
        status = 'complete'
      }

      return { id: activity.id, thumbnail: activity.json['thumb'], status }
    })

    return {
      interactiveConfig,
      lesson,
      activityId,
      sideBarActivityData,
      isAssignmentMode: isAssignment,
    }
  }

  /**
   * Access control to activity based on the student's progress, executed after the model loads.
   *
   * Allows access if:
   *  - `shouldForceAccess()` evaluates to `true`
   *  - the authorisation policy allows access based on student progress
   *
   * @instance afterModel
   * @memberOf StorylandsLessonActivityRoute
   * @param {Object} params The url params
   */
  override afterModel(model: RouteModel | undefined, transition: Transition) {
    const currentRouteName = transition.to?.name
    this.locationTracker.setCurrentRoute(currentRouteName, [
      model?.lesson.id,
      model?.activityId,
    ])
  }

  ensureAccessAllowed(lesson: StorylandsLessonRouteModel, activityId: number) {
    const lastActivity = lesson.activities.length

    if (
      this.assignments.canCompleteStorylandsAssignmentTask(
        lesson.id,
        activityId,
      )
    ) {
      return true
    }
    if (activityId > lastActivity) {
      this.router.transitionTo('storylands.lesson.activity', lastActivity)
      return false
    }
    if (!this.policy.canPlayLessonActivity(lesson.id, activityId)) {
      this.router.transitionTo(
        'storylands.lesson.index',
        this.studentProgress.storylandsCurrentLesson,
      )
      return false
    }
    return true
  }

  /**
   * Called by the application router, this indicates whether the current route can be safely refreshed.
   *
   * @return {boolean} true if the route can be refreshed
   */
  isRefreshable() {
    const quizActivityNumber = 7
    const notALessonQuiz =
      typeof this.currentActivityId === 'number' &&
      this.currentActivityId < quizActivityNumber
    return notALessonQuiz
  }
}
