import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type RouterService from '@ember/routing/router-service'
import type { FeatureService } from '@blakeelearning/features'
import type StudentProgressService from 're-client/services/student-progress'
import type LessonsService from 're-client/services/lessons'
import type CertificateService from 're-client/services/certificate'

interface LessonsMapModel {
  mapId: number
}

interface ParamsForRoute {
  map_id: string
}

export default class LessonsMapRoute extends Route<
  LessonsMapModel,
  ParamsForRoute
> {
  @service declare certificate: CertificateService

  @service declare studentProgress: StudentProgressService

  @service('lessons') declare policy: LessonsService

  @service declare router: RouterService

  @service declare features: FeatureService

  /**
   * Loads the student progress for the precinct
   */
  override async beforeModel() {
    if (this.studentProgress.showLessonsPlacementTest) {
      void this.router.transitionTo('lessons.placement-test')
      return
    }

    if (this.features.isEnabled('bypass_certificate_save')) return

    const hasPendingCertificate = await this.certificate.hasPendingCertificate()
    if (hasPendingCertificate) {
      void this.router.replaceWith('lessons.certificate')
    }
  }

  /**
   * Builds the map model from the map id parameter
   */
  override model(params: ParamsForRoute) {
    const mapId = parseInt(params.map_id, 10)
    this.policy.setCurrentMap(mapId)

    return { mapId }
  }

  override afterModel(model: LessonsMapModel) {
    // playability *only* accounts for if the currentMap is greater than the
    // map asked for in the route, it does not care about overflows ie asking for map 1000
    if (model.mapId !== this.policy.currentMap) {
      void this.router.transitionTo('lessons.map', this.policy.currentMap)
    }
  }
}
