import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import config from 're-client/config/environment'

/**
 * storylands Map Controller
 *
 * @class StorylandsMapController
 */
export default class StorylandsMapController extends Controller {
  @service router

  @service user

  @service studentProgress

  @service('storylands')
  policy

  @action
  playLesson(lessonId) {
    this.router.transitionTo('storylands.lesson', lessonId)
  }

  @action
  playBonusGame(gameId) {
    this.router.transitionTo('storylands.bonus-game', gameId)
  }

  @action
  playQuiz() {
    this.router.transitionTo('storylands.map.quiz', this.model.mapId)
  }

  @action
  prevMap() {
    this.router.transitionTo('storylands.map', this.model.previousMapId)
  }

  @action
  nextMap() {
    const mapId = this.model.mapId
    const { lastMap } = config.studentProgress.progress.storylands
    if (
      mapId === lastMap &&
      this.studentProgress.storylandsCurrentMap > lastMap
    ) {
      this.router.transitionTo('storylands.finished-re-storylands')
    } else {
      this.router.transitionTo('storylands.map', this.model.nextMapId)
    }
  }
}
