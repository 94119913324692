import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type StudentProgressService from 're-client/services/student-progress'
import type UserService from 're-client/services/user'
import type CaperLoaderService from 're-client/services/caper-loader'
import type UrlMakerService from 're-client/services/url-maker'
import type SpellingService from 're-client/services/spelling'
import type RouterService from '@ember/routing/router-service'

export default class QuizRoute extends Route {
  @service
  declare studentProgress: StudentProgressService

  @service
  declare user: UserService

  @service
  declare caperLoader: CaperLoaderService

  @service
  declare urlMaker: UrlMakerService

  @service('spelling')
  declare policy: SpellingService

  @service
  declare router: RouterService

  /**
   * Make sure they can play the quiz on this map, if they can't send them back
   * to their current map.
   */
  override beforeModel() {
    const { canPlayQuizForMap } = this.policy.setCurrentLesson('quiz')

    if (!canPlayQuizForMap) {
      void this.router.transitionTo(
        'spelling.map.index',
        this.studentProgress.spellingCurrentMap,
      )
    }
  }

  override async model() {
    const { currentMap, nextMapId } = this.policy
    const variables = { student: this.user.student }

    const manifests = [
      this.urlMaker.urlForInteractive('spelling/quizzes', currentMap),
    ]

    const interactiveConfig = await this.caperLoader.load(manifests, variables)

    return {
      interactiveConfig,
      mapId: currentMap,
      nextMapId,
    }
  }
}
