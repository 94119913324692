import Service, { inject as service } from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import { sortBy } from 'lodash'
import type { QueryManager } from 'ember-apollo-client'
import type { FixtureFunctionKey } from 're-client/utils/weekly-poll/fake-data'
import { toDateString } from 're-client/utils/format-date'
import { graphql } from 're-client/graphql'
import type { GetPlazaPollsQuery } from 're-client/graphql/graphql'
import type DebugModeService from 're-client/services/debug-mode'
import type UrlMakerService from 're-client/services/url-maker'
import type UserService from 're-client/services/user'
import type ErrorHandlerService from 're-client/services/error-handler'
import { useMutation } from 're-client/resources/mutation'

const getPlazaPollsQueryDocument = graphql(/* GraphQL */ `
  query GetPlazaPolls($currentDate: ISO8601Date!) {
    plazaPolls(input: { currentDate: $currentDate }) {
      id
      startDate
      endDate
      month
      week
      votes {
        count
        answer
      }
    }

    student {
      firstName
      id
      plazaPollVote(dateVoted: $currentDate) {
        dateVoted
        plazaPollId
        votedAnswer
      }
    }
  }
`)

const savePlazaPollVoteMutationDocument = graphql(/* GraphQL */ `
  mutation SavePlazaPollVote($input: PlazaPollVoteCreateInput!) {
    plazaPollVoteCreate(input: $input) {
      dateVoted
      plazaPollId
      votedAnswer
    }
  }
`)

const deletePlazaPollVoteMutationDocument = graphql(/* GraphQL */ `
  mutation DeletePlazaPollVote($input: PlazaPollVoteDestroyInput!) {
    plazaPollVoteDestroy(input: $input) {
      id
      startDate
      endDate
      month
      week
      votes {
        count
        answer
      }
    }
  }
`)

interface CaperPollResult {
  id: number | string
  totalVotesCount: number
  votedAnswersCount: number[]
}

export interface CaperPoll {
  studentVotedToday: boolean
  studentVotedAnswer: number | null
  results: CaperPollResult[]
}

interface PollOptions {
  date?: string | undefined
  voteData?: FixtureFunctionKey | undefined
  refetch?: boolean
}

interface AnswerOptions {
  plazaPollId: string
  votedAnswer: string | number
  date?: string | undefined
  voteData?: FixtureFunctionKey | undefined
}

/**
 * The weekly poll service provides an interface to the underlying weekly poll api.
 */
export default class WeeklyPollService extends Service {
  @queryManager() declare apollo: QueryManager

  @service declare debugMode: DebugModeService

  @service declare urlMaker: UrlMakerService

  @service declare user: UserService

  @service declare errorHandler: ErrorHandlerService

  savePlazaPollVoteMutation = useMutation(
    this,
    savePlazaPollVoteMutationDocument,
  )

  deletePlazaPollVoteMutation = useMutation(
    this,
    deletePlazaPollVoteMutationDocument,
  )

  /**
   * Need to transform poll data from newer GraphQL API (re-student) to match
   * shape from older (deprecated) Elixir API (re_student_api). This is because
   * this data is eventually passed to Caper activities which rely on this data shape.
   * https://github.com/blake-education/caper-activities/blob/develop/lib/assets/javascripts/readingeggs/weekly_poll/weekly_poll.coffee2
   * At the time of this refactor, it was easiest to do the transform here.
   */
  _transformPollDataForCaper({
    student,
    plazaPolls,
  }: GetPlazaPollsQuery): CaperPoll {
    const transformed: CaperPoll = {
      studentVotedAnswer: null,
      studentVotedToday: !!student?.plazaPollVote, // Because this is fetched using the current date, will be null if student hasn't voted today
      results: [],
    }

    if (student?.plazaPollVote) {
      transformed.studentVotedAnswer = student.plazaPollVote.votedAnswer
    }

    for (const poll of plazaPolls) {
      const pollResult: CaperPollResult = {
        id: parseInt(poll.id, 10),
        totalVotesCount: 0,
        votedAnswersCount: [],
      }

      // Votes should come from API ordered by numerical answer key, but just in case...
      const sortedVotes = sortBy(poll.votes, 'answer')

      for (const vote of sortedVotes) {
        pollResult.totalVotesCount += vote.count
        pollResult.votedAnswersCount.push(vote.count)
      }

      transformed.results.push(pollResult)
    }

    return transformed
  }

  /**
   * Get the weekly poll which returns a validated response when valid
   * and returns null when the request or validation fails.
   * This will cause the ui not to render the weekly poll.
   */
  async getWeeklyPoll({
    date,
    refetch = false,
  }: PollOptions = {}): Promise<CaperPoll | null> {
    const currentDate = date ? date : toDateString(new Date())

    try {
      const response = await this.apollo.query({
        query: getPlazaPollsQueryDocument,
        variables: {
          currentDate,
        },
        fetchPolicy: refetch ? 'network-only' : 'cache-first',
      })

      return this._transformPollDataForCaper(response)
    } catch (error) {
      this.errorHandler.logErrorIfActionable(
        'Failed to retrieve weekly poll',
        error,
      )
    }

    return null
  }

  /**
   * Post to the weekly poll endpoint to submit a vote
   */
  async postWeeklyPoll({
    plazaPollId,
    votedAnswer,
    date,
    voteData,
  }: AnswerOptions) {
    try {
      const dateVoted = date ? date : toDateString(new Date())

      if (typeof votedAnswer === 'string') {
        votedAnswer = parseInt(votedAnswer, 10)
      }

      await this.savePlazaPollVoteMutation.current.mutate({
        variables: {
          input: {
            dateVoted,
            plazaPollId,
            votedAnswer: votedAnswer + 1, // Interactive returns 0 indexed answers, but the backend expects 1-4,
          },
        },
      })
    } catch (error) {
      this.errorHandler.logErrorIfActionable(
        'Failed to save weekly poll vote',
        error,
      )
    }

    return await this.getWeeklyPoll({ voteData, date, refetch: true })
  }

  /**
   * Used by debug mode to reset poll data.
   */
  async resetWeeklyPoll(dateVoted: string) {
    if (this.debugMode.enabled) {
      try {
        await this.deletePlazaPollVoteMutation.current.mutate({
          variables: {
            input: {
              dateVoted,
            },
          },
        })
      } catch (error) {
        this.errorHandler.logErrorIfActionable(
          'Failed to reset weekly poll vote',
          error,
        )
      }
    }

    return await this.getWeeklyPoll({ date: dateVoted, refetch: true })
  }
}
