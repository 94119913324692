import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import config from 're-client/config/environment'
import { correctSpellingMap } from 're-client/utils/progress-tools'

export default class StorylandsFinishedReStorylandsController extends Controller {
  @service
  studentProgress

  @service('device/network')
  network

  @service
  offlineScreen

  @service
  router

  @service
  user

  @action
  goToReadingEggspress() {
    this.redirectToReadingEggspress()
  }

  @action
  redirectToReadingEggspress(location = window.location) {
    if (this.network.status.isOffline) {
      this.offlineScreen.display()
      return
    }

    const base = config.APP.apiEndpoint ?? location.origin
    const rexLoginUrl = new URL('rex', base)
    location.assign(rexLoginUrl.toString())
  }

  @action
  goToSkillsBank() {
    this.router.transitionTo(
      'spelling.map',
      correctSpellingMap(this.studentProgress.spellingCurrentMap),
    )
  }

  @action
  goToIndex() {
    this.router.transitionTo('index')
  }

  @action
  goToPrevMap() {
    this.router.transitionTo(
      'storylands.map',
      config.studentProgress.progress.storylands.lastMap,
    )
  }
}
