import { action } from '@ember/object'
import Controller from '@ember/controller'
import { inject as service } from '@ember/service'

export default class ReaderResultController extends Controller {
  @service
  router

  @action
  next() {
    this.router.transitionTo('my-program')
  }
}
