import { indexOf } from 'lodash'

/**
 * Tests if the given touch events registered position sits on the the given element
 * @param {Event} event
 * @param element
 * @returns {boolean}
 */
export function isTouchPosOnElement(event, element) {
  /**
   * NOTE: originalEvent is specific to $(jquery) events
   * deprecated, and removed in ember v4
   * https://deprecations.emberjs.com/v3.x/#toc_jquery-event
   */
  const resolvedEvent =
    event instanceof Event
      ? event
      : event.__originalEvent || event.originalEvent
  const x = resolvedEvent.changedTouches[0].pageX - window.pageXOffset
  const y = resolvedEvent.changedTouches[0].pageY - window.pageYOffset
  const elOfTouchPos = document.elementFromPoint(x, y)
  const elOfTouchPosExists = elOfTouchPos !== null
  const elsAsArray = Array.prototype.slice.call(element.querySelectorAll('*'))
  const elOfTouchPosMatchesComponent =
    elOfTouchPos === element || indexOf(elsAsArray, elOfTouchPos) >= 0
  return elOfTouchPosExists && elOfTouchPosMatchesComponent
}
