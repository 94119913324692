import type {
  ApolloQueryResult,
  OperationVariables,
  WatchQueryOptions,
} from '@apollo/client'
import { cell, resource, use } from 'ember-resources'

export interface WatchQuery<TData, TVariables> {
  data: TData | undefined
  refetch(): Promise<ApolloQueryResult<TData>>
  result(): Promise<ApolloQueryResult<TData>>
  updateQuery(
    mapFn: (
      previousQueryResult: TData,
      options: {
        variables?: TVariables
      },
    ) => TData,
  ): void
}

function Query<
  T = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(options: () => WatchQueryOptions<TVariables, T>): WatchQuery<T, TVariables> {
  return resource(({ owner, on }) => {
    const apollo = owner.lookup('service:apollo')

    const currentResult = cell<T | undefined>()
    const query = apollo.client.watchQuery(options())

    const subscription = query.subscribe((result) => {
      if (!result.partial) {
        currentResult.set(result.data)
      }
    })

    on.cleanup(() => {
      subscription.unsubscribe()
    })

    return () => ({
      data: currentResult.current,
      refetch() {
        return query.refetch()
      },
      result() {
        return query.result()
      },
      updateQuery(mapFn) {
        query.updateQuery(mapFn)
      },
    })
  })
}

/**
 * Watches the cache for changes to the provided query.
 *
 * @returns a resource that will update with the latest query data,
 * and methods to refetch or update the query.
 */
export function useQuery<
  T = unknown,
  TVariables extends OperationVariables = OperationVariables,
>(parent: object, options: () => WatchQueryOptions<TVariables, T>) {
  return use(parent, Query(options))
}
