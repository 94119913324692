import { action } from '@ember/object'
import Controller from '@ember/controller'
import { capitalize } from '@ember/string'
import { inject as service } from '@ember/service'
import config from 're-client/config/environment'
import gsap from 'gsap'
import type StudentProgressService from 're-client/services/student-progress'
import type UserService from 're-client/services/user'
import type RouterService from '@ember/routing/router-service'
import type { ModelFor } from 're-client/utils/route-model'
import type MyProgramRoute from 're-client/routes/my-program'

const {
  APP: { lastSpellingLesson, lastStorylandsLesson },
} = config

export default class MyProgramController extends Controller {
  declare model: ModelFor<MyProgramRoute>

  @service
  declare studentProgress: StudentProgressService

  @service
  declare user: UserService

  @service
  declare router: RouterService

  get firstName() {
    const name = this.user.student.firstName
    return capitalize(name)
  }

  get books() {
    return this.model.books
  }

  get studentAvatarUrl() {
    return this.user.student.avatarUrl
  }

  get hasFinishedLessons() {
    return this.studentProgress.hasFinishedLessons
  }

  get nextLesson() {
    const currentLesson = this.studentProgress.lessonsCurrentLesson

    if (this.studentProgress.showLessonsPlacementTest) {
      return 'Placement Test'
    }
    if (currentLesson === 'quiz') {
      return `Map ${this.studentProgress.lessonsCurrentMap} Quiz`
    }
    return `Lesson ${currentLesson}`
  }

  get isAuLocale() {
    const studentLocale = String(this.user.accent).toLowerCase()
    return studentLocale === 'au'
  }

  // spelling tile should be locked unless student has completed the minimum
  // number of required lessons in the "lessons" precinct
  get spellingLocked() {
    return this.studentProgress.isAreaLocked('spelling')
  }

  // driving tests tile should be locked unless student has completed the minimum
  // number of required lessons in the "lessons" precinct
  get drivingTestsLocked() {
    return this.studentProgress.isAreaLocked('drivingTests')
  }

  @action
  spelling() {
    if (this.spellingLocked) return
    // spelling lesson is set after placement test hence it needs to be
    // shown first, otherwise finished spelling screen shows up
    // if lessons are finished
    if (this.studentProgress.showSpellingPlacementTest) {
      this.router.transitionTo('spelling.placement-test')
      return
    }
    if (
      this.studentProgress.spellingCurrentLessonNumber >= lastSpellingLesson
    ) {
      this.router.transitionTo('spelling.finished-re-spelling')
    } else {
      this.router.transitionTo(
        'spelling.map',
        this.studentProgress.spellingCurrentMap,
      )
    }
  }

  @action
  lessons() {
    const currentLesson = this.studentProgress.lessonsCurrentLesson
    if (currentLesson === 'quiz') {
      this.router.transitionTo(
        'lessons.map.quiz',
        this.studentProgress.lessonsCurrentMap,
      )
      return
    }
    this.router.transitionTo('lessons.lesson', currentLesson)
  }

  @action
  storylands() {
    const { storylandsCurrentLesson } = this.studentProgress
    if (storylandsCurrentLesson > lastStorylandsLesson) {
      this.router.transitionTo('storylands.finished-re-storylands')
    } else {
      this.router.transitionTo('storylands.lesson', storylandsCurrentLesson)
    }
  }

  @action
  drivingTests() {
    if (this.drivingTestsLocked) return
    this.router.transitionTo('driving-tests')
  }

  @action
  goToBookReader(bookId: string) {
    this.router.transitionTo('reader.book', bookId)
  }

  @action
  scrollToBookReader() {
    this._windowScrollTo(0, document.body.scrollHeight)
  }

  @action
  scrollToTop() {
    this._windowScrollTo(0, 0)
  }

  @action
  handleMouseEnter(e: Event) {
    const element = e.target
    const scale = 1.05

    // slight wiggle by running 2 tweens of different length
    gsap.to(element, 1, { scaleX: scale, ease: 'elastic.out' })
    gsap.to(element, 1.5, { scaleY: scale, ease: 'elastic.out' })
  }

  @action
  handleMouseLeave(e: Event) {
    const element = e.target
    gsap.to(element, 2, { scale: 1, ease: 'elastic.out' })
  }

  /**
   * Calls window.scrollTo
   *
   * @param {number} x
   * @param {number} y
   *
   * This function exists to allow easy test stubbing of window.scrollTo
   */
  _windowScrollTo(x: number, y: number) {
    window.scrollTo(x, y)
  }
}

declare module '@ember/controller' {
  interface Registry {
    'my-program': MyProgramController
  }
}
