import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'

/**
 * Spelling Map Controller
 *
 * @class SpellingMapController
 */
export default class IndexController extends Controller {
  @service
  studentProgress

  @service
  user

  @service
  router

  @action
  playLesson(lessonId) {
    return this.router.transitionTo('spelling.lesson', lessonId)
  }

  @action
  playQuiz() {
    this.router.transitionTo('spelling.map.quiz', this.model.mapId)
  }

  @action
  prevMap() {
    this.router.transitionTo('spelling.map.index', this.model.previousMapId)
  }

  @action
  nextMap() {
    if (this.model.isOnFinalMap) {
      this.router.transitionTo('spelling.finished-re-spelling')
    } else {
      this.router.transitionTo('spelling.map.index', this.model.nextMapId)
    }
  }
}
