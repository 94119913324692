import Service from '@ember/service'
import { queryManager } from 'ember-apollo-client'
import type { QueryManager } from 'ember-apollo-client'
import type { FetchPolicy } from '@apollo/client/core'
import { graphql } from 're-client/graphql'
import type { DocumentType } from 're-client/graphql'
import type { CertificateColourEnum } from 're-client/graphql/graphql'
import { useMutation } from 're-client/resources/mutation'

graphql(/* GraphQL */ `
  fragment CertificateFragment on Certificate {
    id
    createdAt
    map
    scorePercentage
    url
  }
`)

const getCertificatesQueryDocument = graphql(/* GraphQL */ `
  query GetCertificates {
    student {
      id
      certificates {
        ...CertificateFragment
      }
    }
  }
`)

export const PendingCertificateFragment = graphql(/* GraphQL */ `
  fragment PendingCertificateFragment on PendingCertificate {
    id
    colour
    map
    quizResultCreatedAt
    scorePercentage
  }
`)

export const pendingCertificateQueryDocument = graphql(/* GraphQL */ `
  query GetPendingCertificate {
    student {
      id
      firstName
      lastName
      name @client
      pendingCertificate {
        ...PendingCertificateFragment
      }
    }
  }
`)

const saveCertificateMutationDocument = graphql(/* GraphQL */ `
  mutation SaveCertificate($input: CertificateSaveInput!) {
    certificateSave(input: $input) {
      certificate {
        id
        url
      }
      student {
        id
        pendingCertificate {
          ...PendingCertificateFragment
        }
        certificates {
          ...CertificateFragment
        }
      }
    }
  }
`)

const getPendingCertificateUploadUrlQueryDocument = graphql(/* GraphQL */ `
  query GetPendingCertificateUploadUrl($input: PendingCertificateQueryInput!) {
    getPendingCertificate(input: $input) {
      id
      uploadUrl
    }
  }
`)

interface UpdatePendingCertificate {
  colour: CertificateColourEnum
  id: string
  map: number
  quizResultCreatedAt: string
  scorePercentage: number
}

interface GetPendingCertificateOptions {
  fetchPolicy?: FetchPolicy
}

interface SaveCertificateOptions {
  width: number
  height: number
  pendingCertificateId: string
}

export default class CertificateService extends Service {
  @queryManager() declare apollo: QueryManager

  saveCertificateMutation = useMutation(this, saveCertificateMutationDocument)

  async getCertificates() {
    const { student } = await this.apollo.query({
      query: getCertificatesQueryDocument,
      fetchPolicy: 'cache-first',
    })

    return student?.certificates
  }

  /**
   * Most of the time this query will just return the data from the apollo cache that was fetched in the lessons route beforeModel().
   * The exception to this is when a student has just finished all of their assignments.
   * In that case this will be the first time we check for a pending certificate.
   */
  getPendingCertificate({
    fetchPolicy = 'cache-first',
  }: GetPendingCertificateOptions = {}) {
    return this.apollo.query({
      query: pendingCertificateQueryDocument,
      fetchPolicy,
    })
  }

  getPendingCertificateUploadUrl(pendingCertificateId: string) {
    return this.apollo.query({
      query: getPendingCertificateUploadUrlQueryDocument,
      variables: {
        input: {
          pendingCertificateId,
        },
      },
      fetchPolicy: 'no-cache',
    })
  }

  async hasPendingCertificate(options?: GetPendingCertificateOptions) {
    const { student } = await this.getPendingCertificate(options)

    return Boolean(student?.pendingCertificate)
  }

  async saveCertificate({
    pendingCertificateId,
    width,
    height,
  }: SaveCertificateOptions) {
    return this.saveCertificateMutation.current.mutate({
      variables: {
        input: {
          pendingCertificateId,
          width,
          height,
        },
      },
    })
  }

  updateCache({
    colour,
    id,
    map,
    quizResultCreatedAt,
    scorePercentage,
  }: UpdatePendingCertificate) {
    const { cache } = this.apollo.apolloClient

    cache.updateQuery({ query: pendingCertificateQueryDocument }, (data) => {
      if (!data?.student) return

      return {
        __typename: 'Query',
        student: {
          ...data.student,
          pendingCertificate: {
            __typename: 'PendingCertificate',
            colour,
            id,
            map,
            quizResultCreatedAt,
            scorePercentage,
          },
        },
      } satisfies DocumentType<typeof pendingCertificateQueryDocument>
    })
  }
}

declare module '@ember/service' {
  interface Registry {
    certificate: CertificateService
  }
}
