import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import { action } from '@ember/object'

export default class MysteryGiftController extends Controller {
  @service
  store

  @service
  user

  @service
  studentProgress

  @service
  router

  @action
  next() {
    this.router.transitionTo(
      'spelling.map.index',
      this.studentProgress.spellingCurrentMap,
    )
  }

  /**
   * Method to call the re api to create a new free item for the current student
   * The records needs to be removed from the ember store as it will be loaded via the changing room again
   *
   * @param itemId
   * @returns {Promise<void>}
   */
  @action
  async grantMysteryItem(itemId) {
    const item = await this.store
      .createRecord('item', { code: itemId, free: 'true' })
      .save()
    item.unloadRecord()
    this.user.setMysteryGiftAvailability(false)
  }
}
