import Route from '@ember/routing/route'
import type RouterService from '@ember/routing/router-service'
import { inject as service } from '@ember/service'
import type LessonsService from 're-client/services/lessons'

/**
 * Handles transitioning to the next route when a quiz is completed or when
 * the right arrow is clicked on a map. If it was
 * the last map, it instead transitions to the finished-re-lessons route.
 *
 * @class LessonsMapNextRoute
 */
export default class LessonsMapNextRoute extends Route {
  @service
  declare router: RouterService

  @service('lessons')
  declare policy: LessonsService

  override redirect() {
    const { isOnFinalMap, nextMapId } = this.policy

    if (isOnFinalMap) {
      void this.router.transitionTo('lessons.finished-re-lessons')
    } else {
      void this.router.transitionTo('lessons.map', nextMapId)
    }
  }
}
