import { action } from '@ember/object'
import { inject as service } from '@ember/service'
import Controller from '@ember/controller'
import config from 're-client/config/environment'

export default class SpellingFinishedReSpellingController extends Controller {
  @service('device/network')
  network

  @service
  offlineScreen

  @service
  router

  @service
  studentProgress

  @service
  user

  @action
  goToReadingEggspress() {
    this.redirectToReadingEggspress()
  }

  @action
  redirectToReadingEggspress(location = window.location) {
    if (this.network.status.isOffline) {
      this.offlineScreen.display()
      return
    }

    const base = config.APP.apiEndpoint ?? location.origin
    const rexLoginUrl = new URL('rex', base)
    location.assign(rexLoginUrl.toString())
  }

  @action
  async goToStorylands() {
    this.router.transitionTo(
      'storylands.map',
      this.studentProgress.storylandsCurrentMap,
    )
  }

  @action
  goToIndex() {
    this.router.transitionTo('index')
  }

  @action
  goToPrevMap() {
    this.router.transitionTo('spelling.map', config.APP.lastSpellingMap)
  }
}
