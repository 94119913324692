import { modifier } from 'ember-modifier'
import gsap from 'gsap'
import MotionPathPlugin from 'gsap/MotionPathPlugin'

gsap.registerPlugin(MotionPathPlugin)

interface NavBannerAnimationSignature {
  Element: HTMLElement
  Args: {
    Named: object
    Positional: []
  }
}

export default modifier<NavBannerAnimationSignature>(
  function navBannerAnimation(element /*, positional, named*/) {
    positionElement(element)
    const popoutTimeline = popOutAnimation(element)

    return () => {
      popoutTimeline.kill()
    }
  },
)

/**
 * Let Tweenmax do the positioning of the is element. There is no need for this to be
 * a computed property as we never change its position
 */
function positionElement(element: HTMLElement) {
  const x = -300
  const y = -150
  gsap.set(element, { left: x, top: y })
}

/**
 * This animation pops in the the theme banner and animates it along a curve
 * to its final position near the top of the screen.
 * Ideally this should be an svg.
 */
function popOutAnimation(element: HTMLElement) {
  const timeline = gsap.timeline({ paused: true })

  // we can use fixed values as this will be scaled on a higher level
  const data = [
    { x: -150, y: -150 },
    { x: 250, y: 400 },
    { x: 650, y: 200 },
    { x: 837, y: 160 },
  ]

  timeline.set(element, { scale: 0.4 })
  timeline.from(element, {
    duration: 1,
    opacity: 0,
    ease: 'elastic.out',
  })
  timeline.to(
    element,
    {
      duration: 2,
      motionPath: { path: data, type: 'cubic' },
      rotation: 370,
      ease: 'power4.easeOut',
    },
    2,
  )

  timeline.play(0)
  return timeline
}
