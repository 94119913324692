import { assert } from '@ember/debug'
import { registerDestructor } from '@ember/destroyable'
import type Owner from '@ember/owner'
import Modifier from 'ember-modifier'
import type { ArgsFor, NamedArgs, PositionalArgs } from 'ember-modifier'
import { bind } from 'bind-event-listener'
import {
  focusElement,
  focusFirstDescendant,
  focusableChildren,
} from 're-client/utils/focusable-elements'

interface DialogModifierSignature {
  Args: {
    Positional: []
    Named: {
      show(event?: Event): void
      hide(event?: Event): void
      open: boolean
    }
  }
}

function cleanup(instance: Dialog) {
  instance.removeClickHandler()
  instance.removeFocusTrap()
  instance.removeKeydownHandler()
}

export default class Dialog extends Modifier<DialogModifierSignature> {
  args: NamedArgs<DialogModifierSignature>

  element: HTMLElement | undefined

  focusLastElement = () => {}
  removeClickHandler = () => {}
  removeFocusTrap = () => {}
  removeKeydownHandler = () => {}

  constructor(owner: Owner, args: ArgsFor<DialogModifierSignature>) {
    super(owner, args)

    this.args = args.named

    registerDestructor(this, cleanup)
  }

  override modify(
    element: Element,
    _: PositionalArgs<DialogModifierSignature>,
    args: NamedArgs<DialogModifierSignature>,
  ) {
    cleanup(this)

    assert('element must be a HTMLElement', element instanceof HTMLElement)

    if (this.element !== element) {
      this.element = element
    }

    element.setAttribute('aria-modal', 'true')

    if (!element.hasAttribute('role')) {
      element.setAttribute('role', 'dialog')
    }

    this.removeClickHandler = bind(document.body, {
      type: 'click',
      listener: (event) => {
        const role = element.getAttribute('role')
        const dialogDocument = element.querySelector('[role="document"]')

        if (
          event.target instanceof Node &&
          dialogDocument?.contains(event.target)
        ) {
          event.preventDefault()
        } else if (role === 'dialog') {
          this.hide(event)
        }
      },
    })

    if (args.open) {
      this.show()
    } else {
      this.hide()
    }
  }

  hide(event?: Event) {
    this.args.hide(event)

    this.element?.setAttribute('aria-hidden', 'true')
    this.element?.setAttribute('tabindex', '-1')

    cleanup(this)

    this.focusLastElement()
  }

  show(event?: Event) {
    this.args.show(event)

    this.element?.removeAttribute('aria-hidden')
    this.element?.removeAttribute('tabindex')

    this.focusLastElement = focusElement(document.activeElement)

    if (this.element) {
      focusFirstDescendant(this.element)
      this.removeFocusTrap = bind(this.element, {
        type: 'keydown',
        listener(keydownEvent) {
          if (
            keydownEvent.currentTarget instanceof Node &&
            keydownEvent.key === 'Tab'
          ) {
            const focused = document.activeElement
            const focusable = focusableChildren(keydownEvent.currentTarget)
            const first = focusable.at(0)
            const last = focusable.at(-1)

            // Focus the last element when navigating backwards from the first element
            if (keydownEvent.shiftKey && focused === first) {
              last?.focus()
              keydownEvent.preventDefault()
            }
            // Focus the first element when navigating forwards from the last element
            else if (!keydownEvent.shiftKey && focused === last) {
              first?.focus()
              keydownEvent.preventDefault()
            }
          }
        },
        options: { capture: true },
      })
    }

    const role = this.element?.getAttribute('role')

    if (role === 'dialog') {
      this.removeKeydownHandler = bind(window, {
        type: 'keydown',
        listener: (keydownEvent) => {
          if (keydownEvent.key === 'Escape') {
            keydownEvent.preventDefault()
            this.hide(keydownEvent)
          }
        },
      })
    }
  }
}
