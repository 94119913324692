import Service, { service } from '@ember/service'
import { tracked } from '@glimmer/tracking'
import config from 're-client/config/environment'
import type StudentProgressService from 're-client/services/student-progress'
import {
  isOnFinalLessonsMap,
  previousLessonMapId,
} from 're-client/utils/progress-tools'
import type AssignmentsService from 're-client/services/assignments'

export default class SpellingService extends Service {
  @service declare assignments: AssignmentsService

  @service declare studentProgress: StudentProgressService

  @tracked
  private map: number | undefined = undefined

  @tracked
  private lesson: string | undefined = undefined

  @tracked
  private activity: number | undefined = undefined

  get currentMap() {
    return this.map ?? this.studentProgress.spellingCurrentMap
  }

  setCurrentMap(mapId: number) {
    if (this.isWithinProgress(mapId)) {
      this.map = Math.min(mapId, config.APP.lastSpellingMap)
      this.lesson = undefined
      this.activity = undefined
    }

    return this
  }

  get currentLesson() {
    if (this.assignments.currentTask?.__typename === 'AssignmentTaskSpelling') {
      return this.assignments.currentTask.lessonId
    }

    return this.lesson ?? this.studentProgress.spellingCurrentLesson
  }

  setCurrentLesson(lesson: string) {
    if (this.isWithinProgress(this.currentMap, lesson)) {
      this.lesson = lesson
      this.activity = undefined
    }

    return this
  }

  get currentActivity() {
    if (this.assignments.currentTask?.__typename === 'AssignmentTaskSpelling') {
      return Number(this.assignments.currentTask.activityId)
    }

    if (this.activity) {
      return this.activity
    }

    if (this.studentProgress.spelling.currentLesson === this.currentLesson) {
      return this.studentProgress.spelling.currentActivity
    }

    return 1
  }

  setCurrentActivity(activity: number) {
    if (this.isWithinProgress(this.currentMap, this.currentLesson, activity)) {
      this.activity = activity
    }

    return this
  }

  get canPlayQuizForMap() {
    return this.isWithinProgress(this.currentMap, 'quiz')
  }

  canPlayActivity(activityId: number) {
    return this.isWithinProgress(
      this.currentMap,
      this.currentLesson,
      activityId,
    )
  }

  isWithinProgress(
    mapId = this.currentMap,
    lessonId = this.currentLesson,
    activityId = this.currentActivity,
  ) {
    const {
      spellingCurrentActivity,
      spellingCurrentLesson,
      spellingCurrentLessonNumber,
      spellingCurrentMap,
    } = this.studentProgress

    const lessonNumber = Number(lessonId)
    const isCurrentLessonQuiz = spellingCurrentLesson === 'quiz'

    const isPreviousMap = mapId < spellingCurrentMap
    const isPreviousLesson = isCurrentLessonQuiz
      ? lessonNumber <= spellingCurrentLessonNumber
      : lessonNumber < spellingCurrentLessonNumber

    const isCurrentMap = mapId === spellingCurrentMap
    const isCurrentLesson = lessonId === spellingCurrentLesson

    if (isCurrentMap && isCurrentLesson) {
      return activityId <= spellingCurrentActivity
    }

    return (isCurrentMap && isPreviousLesson) || isPreviousMap
  }

  get isQuizComplete() {
    return this.currentMap < this.studentProgress.spellingCurrentMap
  }

  get isOnFinalMap() {
    return isOnFinalLessonsMap(this.currentMap, config.APP.lastSpellingMap)
  }

  get previousMapId() {
    return previousLessonMapId(this.currentMap, config.APP.lastSpellingMap)
  }

  get nextMapId() {
    return this.currentMap + 1
  }
}

declare module '@ember/service' {
  interface Registry {
    spelling: SpellingService
  }
}
