import { inject as service } from '@ember/service'
import Route from '@ember/routing/route'
import type SpellingService from 're-client/services/spelling'
import type RouterService from '@ember/routing/router-service'

/**
 * The lesson index route
 *
 * @class LessonIndexRoute
 */
export default class SpellingLessonIndexRoute extends Route {
  @service('spelling')
  declare policy: SpellingService

  @service
  declare router: RouterService

  override beforeModel() {
    const { currentLesson, currentActivity } = this.policy
    void this.router.transitionTo(
      'spelling.lesson.activity',

      currentLesson,
      currentActivity,
    )
  }
}
